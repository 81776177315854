<template>
  <v-container fluid class="pt-5">
    <base-material-card
      color="primary"
      icon="mdi-database-cog"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Versionamento de Bases :: Versões Ativas
        </div>
      </template>

      <v-row class="mt-2 mb-1" justify="space-between">
        <v-col cols="12" sm="4">
          <v-select
            :items="companies"
            clearable
            class="my-0"
            hide-details
            item-text="name"
            item-value="id"
            v-model="selectedCompany"
            label="Empresa"
          />
        </v-col>
      </v-row>
      
      <v-data-table
        dense
        :headers="headers"
        :items="filteredItems"
        :mobile-breakpoint="800"
        :loading="loadingTable"
        :items-per-page="-1"
        :hide-default-header="loadingTable"
        :hide-default-footer="true"
        :sort-by="[]"
        :sort-desc="true"
        no-data-text="Nenhum versionamento encontrado"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | parseNumberToIntegerBR }}
        </template>
        <template v-slot:[`item.data_month`]="{ item }">
          {{ parseMonth(item.data_month) }}
        </template>
        <template v-slot:[`item.active_version`]="{ item }"> 
          <v-row no-gutters>
            <v-col cols="auto" sm="6" md="2" xl="2">
              <v-text-field
                class="my-0"
                dense
                hide-details
                type="number"
                min="0"
                prepend-icon="mdi-numeric-1-box-multiple-outline"
                v-model="item.indger_version.version"
                :value="item.indger_version.version"
                @blur="setSelectedActiveVersion(item)"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom :disabled="item.active_version">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.active_version"
                v-bind="attrs"
                v-on="on"
                color="gray"
                fab
                icon
                x-small
                @click="activateVersion()"
              >
                <v-icon small>
                  mdi-check-bold
                </v-icon>
              </v-btn>
            </template>
            <span>Definir como versão ativa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>

import dayjs from 'dayjs';

import IndgerVersionsService from '@/services/IndgerVersionsService';
import CompanyService from '@/services/CompanyService';

export default {
  name: "VersionamentoBasesVersoesAtivas",
  components: {},
  data: () => ({
    items: [],
    headers: [
      {
        text: 'Empresa',
        value: 'company.name'
      },
      {
        text: 'Competência',
        value: 'data_month'
      },
      {
        text: 'Versão Ativa',
        value: 'active_version',
        sortable: false, 
      },
    ],
    companies: [],
    selectedCompany: null,
    loadingTable: false,
  }),
  computed: {
    filteredItems() {
      let items = this.items;

      if (this.selectedCompany) {
        items = items.filter(item => item.company.id == this.selectedCompany);
      }

      return items;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loadingTable = true;

      Promise.all([
        this.getAllActivatedVersions(),
        this.getCompanies(),
      ])
        .catch(err => {
          console.error(err);
        })
        .finally(() => this.loadingTable = false);
    },
    getAllActivatedVersions() {
      IndgerVersionsService.getAllActivatedVersions()
        .then(res => {
          this.items = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getCompanies() {
      CompanyService.getCompanies()
        .then(res => {
          this.companies = res;
        })
        .catch(err => {
          throw err;
        });
    },
    setSelectedActiveVersion(item) {
      if (item?.indger_version?.version === '' || item?.indger_version?.version === null) return; 

      const postData = {
        company_id: item.company_id,
        data_month: item.data_month,
        selected_version: item.indger_version.version
      };

      IndgerVersionsService.setSelectedActivedVersion(item.id, postData)
        .then(res => {
          this.getData();

          this.$toast.success("Versão ativa alterada.", '', { position: 'topRight' });
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Falha ao alterar versão ativa.', '', { position: 'topRight' });
        });
    },
    parseMonth(date) {
      return dayjs(date).format('MM/YYYY');
    }
  }
}

</script>

<style>

</style>